<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom gutter-b">
          <div class="card-header border-0">
            <h3
              v-if="
                currentUserRole === 'ROLE_ROOT' ||
                currentUserRole === 'ROLE_SUPER_ADMIN'
              "
              class="card-title font-weight-bolder text-dark"
            >
              {{ $t('MAIN_MENU.ALL_COMPANIES') }}
            </h3>
            <h3 v-else class="card-title font-weight-bolder text-dark">
              {{ $t('MAIN_MENU.MY_COMPANIES') }}
            </h3>
            <div class="card-toolbar">
              <router-link
                v-if="
                  currentUserRole === 'ROLE_ROOT' ||
                  currentUserRole === 'ROLE_SUPER_ADMIN'
                "
                to="/companies/new"
                class="btn btn-primary font-weight-bolder"
              >
                <span class="svg-icon svg-icon-sm">
                  <inline-svg
                    src="media/svg/icons/Clothes/Briefcase.svg"
                  /> </span
                >{{ $t('BUTTON.ADD_COMPANY') }}
              </router-link>
            </div>
          </div>
          <div class="card-body pt-0">
            <div
              v-if="
                currentUserRole === 'ROLE_ROOT' ||
                currentUserRole === 'ROLE_SUPER_ADMIN'
              "
              class="row mb-5"
            >
              <div class="col-md-6">
                <div class="form-group mb-0">
                  <input
                    @keyup="searchCompany('name')"
                    v-model="nameQuery"
                    id="company_search_by_name"
                    placeholder="Start typing Company Name"
                    class="form-control form-control-solid form-control-lg"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group mb-0">
                  <input
                    @keyup="searchCompany('oib')"
                    v-model="oibQuery"
                    id="company_search_by_oib"
                    placeholder="Start typing Company OIB"
                    class="form-control form-control-solid form-control-lg"
                  />
                </div>
              </div>
              <div class="col-md-2 mt-1"></div>
            </div>
            <div
              v-if="
                currentUserRole === 'ROLE_ROOT' ||
                currentUserRole === 'ROLE_SUPER_ADMIN'
              "
              class="separator separator-dashed my-5"
            ></div>
            <div class="row">
              <div class="col-md-12">
                <div v-if="!isCompaniesLoaded" class="col-12 text-center my-5 p-2 pt-5 bg-secondary">
                  <div class="text-center my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-4">{{ $t('GENERAL.LOADING') }}</strong>
                  </div>
                </div>
                <div v-else-if="isCompaniesLoaded && companies.length > 0" class="table-responsive mb-0 pb-0">
                  <table
                    class="table table-head-custom table-vertical-center table-head-bg"
                  >
                    <thead>
                      <tr class="text-uppercase">
                        <th class="pl-6">
                          <span
                            @click="orderCompaniesByName"
                            class="cursor-pointer"
                          >
                            <span
                              class="pt-2"
                              :class="{ 'text-primary': nameOrder.isActive }"
                            >
                              {{ $t('COMPANIES.NAME_AND_OIB') }}</span
                            >
                            <span
                              class="svg-icon svg-icon-sm pl-2"
                              :class="{
                                'svg-icon-primary': nameOrder.isActive,
                              }"
                            >
                              <inline-svg
                                v-if="nameOrder.query === 'asc'"
                                src="media/svg/icons/Navigation/Down-2.svg"
                              />
                              <inline-svg
                                v-else
                                src="media/svg/icons/Navigation/Up-2.svg"
                              />
                            </span>
                          </span>
                        </th>
                        <th>{{ $t('GENERAL.EMAIL') }}</th>
                        <th>{{ $t('COMPANIES.MODULE') }}</th>
                        <th class="text-right pr-6" style="min-width: 240px">
                          {{ $t('GENERAL.ACTIONS') }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(company, key) in companies" :key="key">
                        <td class="pl-6" :class="{ 'border-top-0': key === 0 }">
                          <router-link
                            v-if="
                              currentUserRole === 'ROLE_ROOT' ||
                              currentUserRole === 'ROLE_SUPER_ADMIN'
                            "
                            :to="'/companies/edit/' + company.id"
                            class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                            >{{ company.name }}</router-link
                          >
                          <span
                            v-else
                            class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                            >{{ company.name }}</span
                          >
                          <div>
                            <span class="font-weight-bolder">OIB: </span>
                            <a
                              class="text-muted font-weight-bold text-hover-primary"
                              href="#"
                              >{{ company.oib }}</a
                            >
                          </div>
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          <a
                            :href="'mailto:' + company.email"
                            class="text-muted font-weight-500 text-hover-primary"
                            >{{ company.email }}</a
                          >
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          <span
                            v-for="module in company.activeModules"
                            :key="module"
                            class="label label-lg label-light-primary label-inline mr-2"
                            >{{ module }}</span
                          >
                        </td>
                        <td
                          class="text-right pr-6"
                          :class="{ 'border-top-0': key === 0 }"
                        >
                          <button
                            @click="toggleCompanyUsersModal(company.id)"
                            class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                            v-b-tooltip.hover.top="$t('COMPANIES.SHOW_USERS')"
                          >
                            <span class="svg-icon svg-icon-md">
                              <inline-svg
                                src="media/svg/icons/General/User.svg"
                              />
                            </span>
                          </button>
                          <button
                            @click="toggleCompanyLoggersModal(company.id)"
                            class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                            v-b-tooltip.hover.top="$t('COMPANIES.SHOW_LOGGERS')"
                          >
                            <span class="svg-icon svg-icon-md">
                              <inline-svg
                                src="media/svg/icons/Media/Airplay.svg"
                              />
                            </span>
                          </button>
                          <button
                            @click="toggleCompanyDevicesModal(company.id)"
                            class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                            v-b-tooltip.hover.top="
                              $t('COMPANIES.SHOW_DETAILED_VIEW')
                            "
                          >
                            <span class="svg-icon svg-icon-md">
                              <inline-svg
                                src="media/svg/icons/Electric/Outlet.svg"
                              />
                            </span>
                          </button>
                          <router-link
                            v-if="
                              currentUserRole === 'ROLE_ROOT' ||
                              currentUserRole === 'ROLE_SUPER_ADMIN'
                            "
                            :to="'/companies/edit/' + company.id"
                            class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                          >
                            <span class="svg-icon svg-icon-md">
                              <inline-svg
                                src="media/svg/icons/General/Settings-1.svg"
                              />
                            </span>
                          </router-link>
                          <a
                            v-if="
                              currentUserRole === 'ROLE_ROOT' ||
                              currentUserRole === 'ROLE_SUPER_ADMIN'
                            "
                            @click="deleteEntity(company.id)"
                            href="#"
                            class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon"
                          >
                            <span class="svg-icon svg-icon-md">
                              <inline-svg
                                src="media/svg/icons/General/Trash.svg"
                              />
                            </span>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                 <div v-else-if="isCompaniesLoaded && companies.length === 0" class="row" style="display: flex; align-items: center; justify-content: center; flex-direction: column; height: 100%;">
                  <div class="col-12 pt-4 pb-6 text-center px-6 pl-10">
                    <div style="display: flex; align-items: center; justify-content: center; flex-direction: column; height: 100%;">
                      <p class="font-weight-bold" style="font-size: 24px;">No companies.</p>
                      <p style="font-size: 16px;">There are currently no companies.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <b-modal
          style="height: 80vh"
          id="company_users"
          size="lg"
          hide-header
          hide-footer
          center
        >
          <b-button
            class="btn btn-light-primary mb-3"
            block
            @click="$bvModal.hide('company_users')"
            >{{ $t('GENERAL.CLOSE') }}
          </b-button>
          <div style="height: 720px">
            <CompanyUsersTable
              v-if="!this.companyUsersPagination.isLoading"
              :users="companyUsers"
            />
            <div
              v-else
              class="pt-10 d-flex justify-content-center align-items-center"
            >
              <b-spinner variant="primary"></b-spinner>
            </div>
          </div>
          <div class="mt-10 d-flex justify-content-center align-items-center">
            <b-pagination
              v-model="companyUsersPagination.currentPage"
              :total-rows="companyUsersPagination.totalItems"
              :per-page="15"
              size="lg"
            ></b-pagination>
          </div>
        </b-modal>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <b-modal id="company_loggers" size="md" hide-header hide-footer>
          <div v-if="isCompanyLoggersLoaded">
            <b-button
              class="btn btn-light-primary mb-3"
              block
              @click="$bvModal.hide('company_loggers')"
              >{{ $t('GENERAL.CLOSE') }}</b-button
            >
            <div
              v-if="companyLoggersPagination.isLoading"
              class="mt-10 overflow-auto d-flex justify-content-center align-items-center"
            >
              <b-spinner variant="primary"></b-spinner>
            </div>
            <ul v-else>
              <li v-for="(companyLogger, key) in companyLoggers" :key="key">
                <span>{{ companyLogger.imei }}</span>
              </li>
            </ul>
          </div>
          <div
            class="mt-10 overflow-auto d-flex justify-content-center align-items-center"
          >
            <b-pagination
              v-model="companyLoggersPagination.currentPage"
              :total-rows="companyLoggersPagination.totalItems"
              :per-page="30"
              size="lg"
            ></b-pagination>
          </div>
        </b-modal>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <b-modal
          id="company_devices"
          size="xl"
          hide-header
          hide-footer
          centered
        >
          <div>
            <b-button
              class="btn btn-light-primary mb-3"
              block
              @click="$bvModal.hide('company_devices')"
              >{{ $t('GENERAL.CLOSE') }}</b-button
            >
            <div
              v-if="companyDevicesPagination.isLoading"
              class="mt-10 overflow-auto d-flex justify-content-center align-items-center"
            >
              <b-spinner variant="primary"></b-spinner>
            </div>
            <div v-else class="table-responsive mb-0 pb-0">
              <table
                class="table table-head-custom table-vertical-center table-head-bg"
              >
                <thead>
                  <tr class="text-uppercase">
                    <th class="pl-6">
                      {{ $t('COMPANIES.DEVICE_AND_LESSOR') }}
                    </th>
                    <th>Imei</th>
                    <th class="text-right">
                      {{ $t('COMPANIES.SERVICE_MODE') }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(companyDevice, key) in companyDevices" :key="key">
                    <td class="pl-6">
                      <router-link
                        :to="'/devices/edit/' + companyDevice.id"
                        class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                        >{{ companyDevice.name }}
                      </router-link>
                      <div v-if="companyDevice.location">
                        <span class="font-weight-bolder"
                          >{{ $t('MAIN_MENU.LESSOR') }}:
                        </span>
                        <a
                          class="text-muted font-weight-bold text-hover-primary"
                          href="#"
                          >{{ companyDevice.location.title }}</a
                        >
                      </div>
                    </td>
                    <td>
                      <span class="text-muted font-weight-500">{{
                        companyDevice.logger.imei
                      }}</span>
                    </td>
                    <td class="text-right">
                      <span
                        v-if="companyDevice.serviceMode"
                        class="label label-lg label-light-success label-inline"
                        >{{ $t('GENERAL.ON') }}</span
                      >
                      <span
                        v-else
                        class="label label-lg label-light-danger label-inline"
                        >{{ $t('GENERAL.OFF') }}</span
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div
            class="mt-10 overflow-auto d-flex justify-content-center align-items-center"
          >
            <b-pagination
              v-model="companyDevicesPagination.currentPage"
              :total-rows="companyDevicesPagination.totalItems"
              :per-page="30"
              size="lg"
            ></b-pagination>
          </div>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'
import { mapGetters } from 'vuex'
import CompanyUsersTable from './CompanyUsersTable.vue'

export default {
  name: 'List',
  components: { CompanyUsersTable },
  data() {
    return {
      companies: [],
      isCompaniesLoaded: false,
      nameQuery: '',
      oibQuery: '',
      timeout: null,
      debounceMilliseconds: 250,
      nameOrder: {
        query: 'asc',
        isActive: false,
      },
      currentCompanyId: null,

      companyUsers: [],
      isCompanyUsersLoaded: false,
      companyUsersPagination: {
        totalItems: 0,
        currentPage: 1,
        isLoading: false,
      },

      companyLoggers: [],
      isCompanyLoggersLoaded: false,
      companyLoggersPagination: {
        totalItems: 0,
        currentPage: 1,
        isLoading: false,
      },

      companyDevices: [],
      isCompanyDevicesLoaded: false,
      companyDevicesPagination: {
        totalItems: 0,
        currentPage: 1,
        isLoading: false,
      },
    }
  },
  computed: {
    ...mapGetters(['currentUserRole', 'currentUserCompany']),
  },
  watch: {
    'companyUsersPagination.currentPage': function () {
      this.companyUsersPagination.isLoading = true
      this.toggleCompanyUsersModal(this.currentCompanyId)
    },
    'companyDevicesPagination.currentPage': function () {
      this.companyDevicesPagination.isLoading = true
      this.toggleCompanyDevicesModal(this.currentCompanyId)
    },
    'companyLoggersPagination.currentPage': function () {
      this.companyLoggersPagination.isLoading = true
      this.toggleCompanyLoggersModal(this.currentCompanyId)
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Companies', route: '' }])

    if (
      this.currentUserRole === 'ROLE_ADMIN' ||
      this.currentUserRole === 'ROLE_WORKER' ||
      this.currentUserRole === 'ROLE_JUNIOR'
    ) {
      this.getCompanies(
        'companies',
        `${this.currentUserCompany.replace('/api/v1/companies/', '')}`
      ).then(({ data }) => {
        this.companies.push(data)
        this.isCompaniesLoaded = true
      })
    }

    if (
      this.currentUserRole === 'ROLE_ROOT' ||
      this.currentUserRole === 'ROLE_SUPER_ADMIN'
    ) {
      this.getCompanies(
        'companies',
        '?properties[]=id&properties[]=name&properties[]=oib&properties[]=email&properties[]=activeModules'
      ).then(({ data }) => {
        data['hydra:member'].forEach((company) => {
          this.companies.push(company)
        })
        if (data['hydra:totalItems'] > 100) {
          this.getCompanies(
            'companies',
            '?properties[]=id&properties[]=name&properties[]=oib&properties[]=email&properties[]=activeModules&page=2'
          ).then(({ data }) => {
            data['hydra:member'].forEach((company) => {
              this.companies.push(company)
            })
          })
        }
        this.isCompaniesLoaded = true
      })
    }
  },
  methods: {
    async getCompanies(resource, slug = '') {
      try {
        return await ApiService.get(resource, slug)
      } catch (e) {
        throw new Error(e)
      }
    },
    searchCompany(type) {
      clearTimeout(this.timeout)

      this.timeout = setTimeout(() => {
        if (type === 'name') {
          const companyPromise = ApiService.get(
            'companies',
            `?name=${this.nameQuery}`
          )

          Promise.all([companyPromise]).then((values) => {
            this.companies = []
            this.companies = [...values[0].data['hydra:member']]
          })
        }

        if (type === 'oib') {
          const companyPromise = ApiService.get(
            'companies',
            `?oib=${this.oibQuery}`
          )

          Promise.all([companyPromise]).then((values) => {
            this.companies = []
            this.companies = [...values[0].data['hydra:member']]
          })
        }
      }, this.debounceMilliseconds)
    },
    orderCompaniesByName() {
      this.nameOrder.isActive = true

      if (this.nameOrder.query === 'asc') this.nameOrder.query = 'desc'
      else this.nameOrder.query = 'asc'

      this.isCompaniesLoaded = false
      this.companies = []

      this.getCompanies(
        'companies',
        `?order[name]=${this.nameOrder.query}`
      ).then(({ data }) => {
        this.companies = [...data['hydra:member']]
        this.isCompaniesLoaded = true
      })
    },
    deleteEntity(id) {
      let confirmation = confirm('Do you want to delete this company?')
      if (confirmation === false) return

      this.isCompaniesLoaded = false

      ApiService.delete(`companies/${id}`).then(() => {
        this.$notify({
          group: 'notification',
          type: 'success',
          title: 'Company successfully deleted',
        })

        ApiService.get('companies').then((response) => {
          this.companies = [...response.data['hydra:member']]
          this.isCompaniesLoaded = true
        })
      })
    },
    exportAsExcel() {
      const format = 'xlsx'
      const exportSelectedOnly = false
      const filename = 'vt_companies'
      this.$refs.companies.exportTable(format, exportSelectedOnly, filename)
    },
    toggleCompanyUsersModal(companyId) {
      ApiService.get(
        'users',
        `?company=${companyId}&itemsPerPage=15&page=${this.companyUsersPagination.currentPage}`
      ).then(({ data }) => {
        this.companyUsers = []
        this.companyUsers = [...data['hydra:member']]
        this.isCompanyUsersLoaded = true
        this.companyUsersPagination.totalItems = data['hydra:totalItems']
        this.companyUsersPagination.isLoading = false

        this.currentCompanyId = companyId

        this.$root.$emit('bv::show::modal', 'company_users')
      })
    },
    toggleCompanyLoggersModal(companyId) {
      ApiService.get(
        'loggers',
        `?company=${companyId}&page=${this.companyLoggersPagination.currentPage}`
      ).then(({ data }) => {
        this.companyLoggers = []
        this.companyLoggers = [...data['hydra:member']]
        this.isCompanyLoggersLoaded = true
        this.companyLoggersPagination.totalItems = data['hydra:totalItems']
        this.companyLoggersPagination.isLoading = false

        this.currentCompanyId = companyId

        this.$root.$emit('bv::show::modal', 'company_loggers')
      })
    },
    toggleCompanyDevicesModal(companyId) {
      ApiService.get(
        'devices',
        `?company=${companyId}&page=${this.companyDevicesPagination.currentPage}`
      ).then(({ data }) => {
        this.companyDevices = []
        this.companyDevices = [...data['hydra:member']]
        this.isCompanyDevicesLoaded = true
        this.companyDevicesPagination.totalItems = data['hydra:totalItems']
        this.companyDevicesPagination.isLoading = false

        this.currentCompanyId = companyId

        this.$root.$emit('bv::show::modal', 'company_devices')
      })
    },
  },
}
</script>

<style lang="scss">
.modal-body {
  height: 900px !important;
}
</style>
