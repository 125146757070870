<template>
  <div style="width: 100%">
    <div class="table-responsive mb-0 pb-0">
      <table
        class="table table-head-custom table-vertical-center table-head-bg"
      >
        <thead>
          <tr class="text-uppercase">
            <th class="pl-6">
              <span class="pt-2">
                {{ $t('GENERAL.NAME') }}
              </span>
            </th>
            <th class="pl-6">
              <span class="pt-2">
                {{ $t('GENERAL.EMAIL') }}
              </span>
            </th>
            <th class="pl-6">
              <span class="pt-2">
                {{ $t('GENERAL.ROLE') }}
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(user, key) in users" :key="key + '_' + key">
            <td class="pl-6" :class="{ 'border-top-0': key === 0 }">
              <span class="text-dark-75 font-weight-bolder mb-1 font-size-lg">{{
                user.name
              }}</span>
            </td>
            <td class="pl-6" :class="{ 'border-top-0': key === 0 }">
              <span class="text-dark-75 font-weight-bolder mb-1 font-size-lg">{{
                user.email
              }}</span>
            </td>
            <td>
              <span
                v-for="(role, key) in user.roles"
                :key="key"
                class="label label-sm label-light-primary label-inline mr-2"
                >{{ role }}</span
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CompanyUsersTable',
  props: {
    users: { type: Array },
  },
}
</script>
